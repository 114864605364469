import { meshBounds, Text } from "@react-three/drei";
import React from "react";



function Project(props) {
  return <Text
    position={props.position}
    rotation={props.rotation}
    fontSize={0.6}
    maxWidth={10}
    textAlign={'left'}
    color="white" // default
    anchorX="left" // default
    anchorY="top" // default
    onClick={() => window.open(props.link)}
  >
    {props.text}
  </Text>
}

export function Projects(props) {
  return <>
    <group raycast={meshBounds} position={[-0.6, 0, 0]} visible={props.visible}>
      <Project text={"DemoCrisis"} link={"https://www.europeantheatre.eu/news/democrisis-young-europe-3"} position={[2, 1, 2]} rotation={[0, -Math.PI / 4, 0]} />
      <Project text={"Divert.Space"} link={"http://divert.space"} position={[-3, 1, 4]} rotation={[0, Math.PI / 4, 0]} />
      <Project text={"NippleJesus"} link={"https://www.theater-magdeburg.de/en/spielplan/schauspiel/sz-20212022/premieren-2122/nipplejesus/"} position={[-3, 0, 4]} rotation={[0, Math.PI / 4, 0]} />
      <Project text={"setup.school()"} link={"https://www.theater-baden-baden.de/junges-theater/programm/stueck/setupschool-die-lernmaschine"} position={[2, 0, 2]} rotation={[0, -Math.PI / 4, 0]} />
      <Project text={"Kalte Ecken im Koffer"} link={"https://youtu.be/N9N8URvNQFo"} position={[2, -1, 2]} rotation={[0, -Math.PI / 4, 0]} />
      <Project text={"LIFE"} link={"http://outofthebox-now.de/2020/11/20/life-eine-dna-engine/"} position={[-3, -1, 4]} rotation={[0, Math.PI / 4, 0]} />
    </group>
  </>;
}