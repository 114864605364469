import { useLoader } from "@react-three/fiber";
import React from "react";
import * as THREE from "three";
import picUrl from "../../assets/Fotos/anton.jpg";


export default function FotoScreen() {

  const texture = useLoader(THREE.TextureLoader, picUrl);

  return (
    <mesh rotation={[0, -Math.PI / 2, 0]} position={[-1.55, -0.05, 0]} scale={0.22}>
      <planeBufferGeometry attach="geometry" args={[6.3, 8.82]} />
      <meshBasicMaterial attach="material" map={texture} side={THREE.DoubleSide} />
    </mesh>
  )
}