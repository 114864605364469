import { meshBounds, MeshWobbleMaterial, RoundedBox, Text } from "@react-three/drei";
import React, { useEffect, useRef, useState } from "react";
import FotoScreen from "../FotoScreen";
import { VideoScreen } from "../VideoScreen";
import cv from "../../assets/pdf/cv.pdf";
import { Projects } from "../Projects";

export function MenuBox(props) {

  const [hovered, setHovered] = useState(false);
  const [projectsShow, setProjects] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  return (
    <group>
      <RoundedBox
        //onClick={(e) => console.log(e.face.normal)}
        args={[3, 3, 3]} // Width, Height and Depth of the box
        radius={0.5} // Border-Radius of the box
        smoothness={14} // Optional, number of subdivisions
      // All THREE.Mesh props are valid
      >
        <MeshWobbleMaterial
          attach="material"
          factor={0.3} // Strength, 0 disables the effect (default=1)
          speed={0.5} // Speed (default=1)
        />
      </RoundedBox>
      <Text
        raycast={meshBounds}
        position={[-0.9, 0.7, 1.6]}
        fontSize={0.5}
        maxWidth={1}
        textAlign={'center'}
        color="black" // default
        anchorX="left" // default
        anchorY="top" // default
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={() => projectsShow ? setProjects(false) : setProjects(true)}
      >
        current Projects
      </Text>

      <Text
        raycast={meshBounds}
        position={[-1, 1.55, -0.2]}
        rotation={[Math.PI / -2, 0, 0]}
        fontSize={0.5}
        maxWidth={1}
        textAlign={'center'}
        color="black" // default
        anchorX="left" // default
        anchorY="top" // default
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={() => window.open("mailto: anton.k.krause@gmail.com")}
      >
        Contacts
      </Text>

      <Text
        raycast={meshBounds}
        position={[0.9, 0.9, -1.6]}
        rotation={[0, Math.PI, 0]}
        fontSize={1.5}
        maxWidth={1}
        textAlign={'center'}
        color="black" // default
        anchorX="left" // default
        anchorY="top" // default
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onDoubleClick={() => window.open(cv, '_blank', 'fullscreen=yes')} //load pdf
      >
        CV
      </Text>

      <Text
        position={[-1, -1.55, 1]}
        rotation={[Math.PI / 2, 0, 0]}
        fontSize={0.5}
        maxWidth={1}
        textAlign={'left'}
        color="black" // default
        anchorX="left" // default
        anchorY="top" // default
      >
        Anton Kurt Krause
      </Text>

      <VideoScreen />
      <FotoScreen />
      <Projects visible={projectsShow} />


    </group>
  );
}