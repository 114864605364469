import { OrbitControls, Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense } from 'react';
import './App.css';
import { MenuBox } from './components/MenuBox';
import styled from 'styled-components';
import { Ui } from './components/UI';

export default function App() {

  const CanvasContainer = styled.div`
 width: 100%;
 height: 100%;
`;

  return <>
    <Ui />
    <CanvasContainer>
      <Canvas>
        <Suspense fallback={null}>
          <MenuBox />
          <directionalLight position={[20, 20, 20]} intensity={0.3} />
          <directionalLight position={[-20, -20, -20]} />
          <OrbitControls />
          <Stars />
        </Suspense>
      </Canvas>
    </CanvasContainer>
  </>;
}

